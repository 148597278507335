import { Injectable } from '@angular/core';
// import { Events } from '@ionic/angular';
import { UserOptions } from '../interfaces/user-options';
import { environment } from '../../environments/environment';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { HelperProvider } from './helper.provider';
import { AuthInterface } from '../interfaces/auth.interface';
import { md5 } from './md5';
import { OfficeHelperService } from './office-helper/office-helper.service';
import jwtDecode from 'jwt-decode';
import * as _ from 'lodash';
import { Router } from '@angular/router';
import { LoadingController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class AuthProvider {
    _favorites: string[] = [];
    HAS_LOGGED_IN = 'hasLoggedIn';
    HAS_SEEN_TUTORIAL = 'hasSeenTutorial';
    isAuthenticated: boolean;
    loadingInstance;

    constructor(
        // public events: Events,
        private http: HttpClient,
        private router: Router,
        private loadingCtrl: LoadingController,
        private helperProvider: HelperProvider,
        private officeHelper: OfficeHelperService
    ) { }

    hasFavorite(sessionName: string): boolean {
        return (this._favorites.indexOf(sessionName) > -1);
    }

    addFavorite(sessionName: string): void {
        this._favorites.push(sessionName);
    }

    removeFavorite(sessionName: string): void {
        const index = this._favorites.indexOf(sessionName);
        if (index > -1) {
        this._favorites.splice(index, 1);
        }
    }

    async login(userOptions: UserOptions): Promise<any> {
        try {
            window.localStorage.setItem(this.HAS_LOGGED_IN, true + '');
            this.setUsername(userOptions.username);
            const loginRes = await this.serverLogin(userOptions);
            console.log('loginRes', loginRes);
            await this.setAllCredentials(loginRes);
            return loginRes;
        } catch (error) {
            throw error;
        }
    }

    async signupApi(userOptions: any): Promise<any> {
        return new Promise((resolve, reject) => {
            const header = new HttpHeaders();
            header.append('Content-Type', 'application/json');
            userOptions.password = md5(userOptions.password);
            this.http.post(`${environment.serverUrl}api/registration`, userOptions, {headers: header})
            .subscribe((loginRes: Response) => {
                this.helperProvider.buildBackendResponse(loginRes, resolve, reject);
            }, err => this.helperProvider.buildHttpError(err, reject));
        });
    }

    async forgotPasswordApi(userOptions: any): Promise<any> {
        return new Promise((resolve, reject) => {
            const header = new HttpHeaders();
            header.append('Content-Type', 'application/json');
            this.http.post(`${environment.serverUrl}api/auth/forgot`, userOptions, {headers: header})
            .subscribe((loginRes: Response) => {
                this.helperProvider.buildBackendResponse(loginRes, resolve, reject);
            }, err => this.helperProvider.buildHttpError(err, reject));
        });
    }

    signup(username: string) {
        window.localStorage.setItem(this.HAS_LOGGED_IN, true + '');
        this.setUsername(username);
        return this.helperProvider.onEvent.next('user:signup');
    }

    logout() {
        window.localStorage.clear();
        window.localStorage.removeItem(this.HAS_LOGGED_IN);
        window.localStorage.removeItem('username');
        this.helperProvider.onEvent.next('user:logout');
        this.router.navigateByUrl('/login');
    }

    setUsername(username: string) {
        return window.localStorage.setItem('username', username);
    }

    getUsername() {
        return window.localStorage.getItem('username');
    }

    isLoggedIn() {
        const value = window.localStorage.getItem(this.HAS_LOGGED_IN);
        return value === true+'';
    }

    checkHasSeenTutorial() {
        return window.localStorage.getItem(this.HAS_SEEN_TUTORIAL);
    }

    async setAllCredentials(loginResponse: any) {
        try {
            if (loginResponse) {
                const tokenData: any = jwtDecode(loginResponse.token);
                window.localStorage.setItem('ngStorage-token', loginResponse.token);
                // get profile data
                const profileData = await this.helperProvider.getProfileData((tokenData && tokenData._id) || '');
                window.localStorage.setItem('ngStorage-profile', JSON.stringify(profileData));

                if (tokenData && tokenData.lastSelectedOffice) {
                    //  move to last selected office
                    const officeData = await this.officeHelper.getOfficeData(tokenData.lastSelectedOffice);
                    if (officeData) {
                        this.afterLogin(loginResponse.token);
                        if (!officeData.showOnMobile) {
                            return this.router.navigateByUrl('/device-list');
                        }
                        window.localStorage.setItem('ngStorage-selectedOffice', JSON.stringify(officeData));
                        setTimeout(() => {
                            this.helperProvider.onEvent.next('user:officeChanged');
                        }, 1000);
                    } else {
                        throw new Error('Office data empty');
                    }
                } else {
                    // move to dashboard
                    console.log('move to dashboard');
                }
            }
            return null;
        } catch (err) {
            console.log(err);
        }
    }

    serverLogin(loginCredentials: any): Promise<any> {
        return new Promise((resolve, reject) => {
            loginCredentials.login = loginCredentials.username;
            delete loginCredentials.username;
            const header = new HttpHeaders();
            if (loginCredentials.password) {
                loginCredentials.password = md5(loginCredentials.password);
            }
            header.append('Content-Type', 'application/json');
            this.http.post(`${environment.serverUrl}api/auth`, loginCredentials, {headers: header})
            .subscribe((loginRes: Response) => {
                this.helperProvider.buildBackendResponse(loginRes, resolve, reject);
            }, err => this.helperProvider.buildHttpError(err, reject));
        });
    }

    setToken(token: string) {
        const decodedToken: any = jwtDecode(token);
        const credentials = {
            username: decodedToken.login,
            token
        };
        this.isAuthenticated = true;
        // window.localStorage.setItem(token, JSON.stringify(credentials));
    }

    loadAllData(res: any = {}) {
        window.localStorage.setItem('ngStorage-profile', JSON.stringify(res.contactData || {}));
        window.localStorage.setItem('selectedLanguage', (res.contactData && res.contactData.defaultLang) || 'en');
        if (res.extraInfo && res.extraInfo.officeDoc) {
            // this.authStore.update({ officeName: res.extraInfo.officeDoc.name });
            // this.officeStore.update(res.extraInfo.officeDoc);
            window.localStorage.setItem('selectedOfficeId', res.extraInfo.officeDoc._id);
            window.localStorage.setItem('ngStorage-selectedOffice', JSON.stringify(res.extraInfo.officeDoc));
            // this.onOfficeChange.next(res.extraInfo.officeDoc['_id']);
        }
    }

    getRedirectURL() {
        return '/app';
    }

    getOfficeInfo(extraInfo: any = {}) {
        return {
            officeId: (extraInfo.officeDoc && extraInfo.officeDoc._id) || '',
            officeName: (extraInfo.officeDoc && extraInfo.officeDoc.name) || '',
        };
    }

    isUserAdmin({extraInfo, contactData}: any) {
        try {
            const officeDoc = extraInfo.officeDoc || {};
            const profileDoc = contactData || {};
            const usrDoc = _.find(officeDoc.users || [], { id: profileDoc.profileId });
            if (usrDoc && usrDoc.isAdmin) {
                return { isAdmin: true };
            } else {
                return { isAdmin: false };
            }
        } catch (error) {
            throw error;
        }
    };

    getUserInfo(contactData: any = {}) {
        return {
            email: (contactData.email) || '',
            profileId: (contactData.profileId) || '',
            defaultLang: (contactData.defaultLang) || 'en'
        };
    }

    afterLogin(token: string) {
        return new Promise((resolve, reject) => {
            try {
                this.setToken(token);
                const header = new HttpHeaders({
                    Authorization: token,
                    'Content-Type': 'application/json'
                });
                this.http.get(`${environment.serverUrl}api/profile/contactData`, { headers: header })
                .subscribe((data: any) => {
                    data = data.result;
                    this.loadAllData(data);
                    data.extraInfo = data.extraInfo || {};
                    data.extraInfo.initUrl = this.getRedirectURL() || data.extraInfo.initUrl || '/device-list';
                    const officeDoc = (data.extraInfo && data.extraInfo.officeDoc) || {};
                    this.helperProvider.onEvent.next('user:login');
                    resolve({
                        token,
                        // redirectUrl: this.getRedirectURL() || data.extraInfo.initUrl || '/device-list',
                        redirectUrl: `/pending-payment?redirectUrl=${this.getRedirectURL() || data.extraInfo.initUrl || '/device-list'}`,
                        ...this.getOfficeInfo(data.extraInfo),
                        ...this.isUserAdmin(data),
                        ...this.getUserInfo(data.contactData)
                    });
                    // if (officeDoc.ussageBalance) {
                    //     setTimeout(() => {
                    //         this.router.navigate(["/pending-payment"], { queryParams: { redirectUrl: this.getRedirectURL() || data.extraInfo.initUrl || '/device-list' } })
                    //     }, 400);

                    // } else {
                    //     this.router.navigate([data.extraInfo.initUrl]);
                    // }
                }, err => {
                    reject(err);
                });
            } catch (error) {
                reject(error);
            }
        });
    }


    serverSignUp(signUpCredentials: any): Promise<any> {
        return new Promise((resolve, reject) => {
            signUpCredentials.login = signUpCredentials.username;
            delete signUpCredentials.username;
            const header = new HttpHeaders();
            if (signUpCredentials.password) {
                signUpCredentials.password = md5(signUpCredentials.password);
            }
            header.append('Content-Type', 'application/json');
            this.http.post(`${environment.serverUrl}api/registration`, signUpCredentials, {headers: header})
            .subscribe((loginRes: Response) => {
                this.helperProvider.buildBackendResponse(loginRes, resolve, reject);
            }, err => this.helperProvider.buildHttpError(err, reject));
        });
    }

    getLocalUserData() {
        if (localStorage.getItem('ngStorage-profile')) {
            return JSON.parse(localStorage.getItem('ngStorage-profile'));
        } else {
            return null;
        }
    }

    async startLoading(message = 'Loading...') {
        if (!this.loadingInstance) {
            this.loadingInstance = await this.loadingCtrl.create({
                message,
                // duration: 3000,
              });
    
              this.loadingInstance.present();
        }
    }

    async closeLoading() {
        if (this.loadingInstance) {
            await this.loadingInstance.dismiss()
            this.loadingInstance = null;
        }
    }

}
