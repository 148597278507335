import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  // {
  //   path: '',
  //   redirectTo: 'folder/Inbox',
  //   pathMatch: 'full'
  // },
  // {
  //   path: 'folder/:id',
  //   loadChildren: () => import('./folder/folder.module').then( m => m.FolderPageModule)
  // },
  {
    path: '',
    redirectTo: '/login',
    pathMatch: 'full'
  },
  {
    path: 'profile',
    loadChildren: () => import('./pages/account/account.module').then( m => m.AccountModule)
  },
  {
    path: 'support',
    loadChildren: () => import('./pages/support/support.module').then( m => m.SupportModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginModule)
  },
  {
    path: 'signup',
    loadChildren: () => import('./pages/signup/signup.module').then( m => m.SignUpModule)
  },
  {
    path: 'app123',
    loadChildren: () => import('./pages/tabs-page/tabs-page.module').then( m => m.TabsModule)
  },
  {
    path: 'tutorial',
    loadChildren: () => import('./pages/tutorial/tutorial.module').then( m => m.TutorialModule)
  },
  { 
    path: 'app456',
    loadChildren: () => import('./pages/dashboard/dashboard.module').then( m => m.DashboardPageModule)
  },
  { 
    path: 'office-list',
    loadChildren: () => import('./pages/office-list/office-list.module').then( m => m.OfficeListPageModule)
  },
  // { 
  //   path: 'calendar-app',
  //   loadChildren: () => import('./pages/calendar-app/calendar-app.module').then( m => m.CalendarAppModule)
  // },
  { 
    path: 'storage-manager',
    loadChildren: () => import('./pages/storage-manager/storage-manager.module').then( m => m.StorageManagerPageModule)
  },
  { 
    path: 'filter-popover',
    loadChildren: () => import('./pages/calendar-app/filter-popover/filter-popover.module').then( m => m.FilterPopoverPageModule)
  },
  { 
    path: 'issue-reporter/:type/:regId/:regName',
    loadChildren: () => import('./pages/issue-reporter/issue-reporter.module').then( m => m.IssueReporterPageModule)
  },
  { 
    path: 'register-list',
    loadChildren: () => import('./pages/register-list/register-list.module').then( m => m.RegisterListPageModule)
  },
  { 
    path: 'offline',
    loadChildren: () => import('./pages/offline/offline.module').then( m => m.OfflinePageModule)
  },
  {
    path: 'reorder-record-list',
    loadChildren: () => import('./pages/register-list/reorder-record-list/reorder-record-list.module').then( m => m.ReorderRecordListPageModule)
  },
  {
    path: 'device-list',
    loadChildren: () => import('./pages/device-list/device-list.module').then( m => m.DeviceListPageModule)
  },
  {
    path: 'device-add',
    loadChildren: () => import('./pages/device-add/device-add.module').then( m => m.DeviceAddPageModule)
  },
  {
    path: 'device/:sensorId',
    loadChildren: () => import('./pages/device-detail/device-detail.module').then( m => m.DeviceDetailPageModule)
  },
  {
    path: 'forgot-password',
    loadChildren: () => import('./pages/forgot-password/forgot-password.module').then( m => m.ForgotPasswordPageModule)
  },
  {
    path: 'urgent-order',
    loadChildren: () => import('./pages/urgent-order/urgent-order.module').then( m => m.UrgentOrderPageModule)
  },
  {
    path: 'payment-status',
    loadChildren: () => import('./pages/payment-status/payment-status.module').then( m => m.PaymentStatusPageModule)
  },
  {
    path: 'pickup-confirm',
    loadChildren: () => import('./pages/pickup-confirm/pickup-confirm.module').then( m => m.PickupConfirmPageModule)
  },
  {
    path: 'support-ticket',
    loadChildren: () => import('./pages/support-ticket/support-ticket.module').then( m => m.SupportTicketPageModule)
  },
  {
    path: 'payment-history',
    loadChildren: () => import('./pages/payment-history-list/payment-history-list.module').then( m => m.PaymentHistoryListPageModule)
  },
  {
    path: 'pickup-history',
    loadChildren: () => import('./pages/pickup-history-list/pickup-history-list.module').then( m => m.PickupHistoryListPageModule)
  },
  {
    path: 'contracts',
    loadChildren: () => import('./pages/contract-list/contract-list.module').then( m => m.ContractListPageModule)
  },
  {
    path: 'support-requests',
    loadChildren: () => import('./pages/support-request-list/support-request-list.module').then( m => m.SupportRequestListPageModule)
  },
  {
    path: 'support-request-detail',
    loadChildren: () => import('./pages/support-request-detail/support-request-detail.module').then( m => m.SupportRequestDetailPageModule)
  },
  {
    path: 'share-access-list',
    loadChildren: () => import('./pages/share-access-list/share-access-list.module').then( m => m.ShareAccessListPageModule)
  },
  {
    path: 'share-access-form',
    loadChildren: () => import('./pages/share-access-form/share-access-form.module').then( m => m.ShareAccessFormPageModule)
  },
  {
    path: 'add-money',
    loadChildren: () => import('./pages/add-money/add-money.module').then( m => m.AddMoneyPageModule)
  },
  {
    path: 'owner-detail',
    loadChildren: () => import('./pages/owner-detail/owner-detail.module').then( m => m.OwnerDetailPageModule)
  },
  {
    path: 'share-access-edit',
    loadChildren: () => import('./pages/share-access-edit/share-access-edit.module').then( m => m.ShareAccessEditPageModule)
  },
  {
    path: 'notification-settings',
    loadChildren: () => import('./pages/notification-settings/notification-settings.module').then( m => m.NotificationSettingsPageModule)
  },
  // {
  //   path: 'select-modal',
  //   loadChildren: () => import('./components/select-modal/select-modal.module').then( m => m.SelectModalPageModule)
  // },
  // { path: 'record-edit', loadChildren: './pages/register-list/record-edit/record-edit.module#RecordEditPageModule' },


  // { path: 'record-list', loadChildren: './pages/register-list/record-list/record-list.module#RecordListPageModule' },


];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
