export const locale = {
    lang: 'pl',
    data:  {
        basic: {
            save: 'Zapisz',
            cancel: 'Anuluj',
            done: 'Wykonane',
            edit:  'Edytuj',
            close: 'Zamknij',
            next: 'Dalej',
            version: 'Wersja',
            env: 'Env',
            search: 'Szukaj',
        },
        auth: {
            login_heading: 'Login',
            email: 'Email',
            email_required: 'Email jest wymagany',
            password: 'Hasło',
            password_required: 'Hasło jest wymagane',
            login: 'Login',
            signup_heading: 'Zarejestruj się',
            signup_here: 'Zapisz się',
            forgot_password: 'Nie pamiętasz hasła?',
            confirm_password: 'Powtórz hasło',
            confirm_pass_required: 'Powtórzenie hasła jest wymagane',
            confirm_pass_not_match: 'Pola z hasłami muszą być takie same',
            already_have_account: 'Już masz konto?',
            register: 'Rejestr',
            forgot_password_heading: 'Resetowanie hasła',
            send_mail: 'Wyślij email'

        },
        menu: {
            office: 'Biuro',
            office_list: 'Lista biur',
            issue_reporter: 'Zgłoś problem',
            logout: 'Wyloguj'
        },
        record: {
            add_new: 'Dodaj nowy',
            select_your_address: 'Wybierz swój adres',
            cancel: 'Anuluj',
            pay: 'Zapłać',
            tank_capacity: 'Pojemność zbiornika',
            search_address: 'Wyszukaj adres',
            no_records_found: 'Nie odnaleziono wpisów',
            create_new: 'Dodaj nowy',
            selected_address: 'Wybrany adres',
            continue: 'Dalej',
            sensor_id: 'Id Czujnika',
            sensor_id_desc: 'Czujnik, który prognozuje aktualny poziom zajętości twojego zbiornika',
            tank_capacity_desc: 'Całkowita pojemność twojego zbiornika',
            distance_from_road: 'Odległość podłączenia zbiornika od drogi',
            distance_from_road_desc: 'Jak daleko w metrach operator szambiarki musi rozwinąć wąż by podłączyć się do Twojego zbiornika mając auto zaparkowane na drodze',
            how_to_access_tank_desc: 'Czy są jakiekkolwiek problemy w przy podłączaniu się do zbiornika bez asysty osoby mieszkającej na posesji?',
            how_to_access_tank_desc_desc: 'Proszę opisz jeśli są jakiekolwiek ograniczenia z dostępem do zbiornika (zamknięta brama, roboty budowlane, rozkopana droga)',
            urgent_order: 'Zamówienie pilne',
            connector: 'Złączka',
            entry: 'Właz',
            connector_desc: 'Czy twój zbiornik posiada szybkozłączkę, czy operator szambiarki musi otwierać właz do zbiornika przy realizacji zlecenia',
            sensor_name: 'Nazwa czujnika',
            sensor_name_desc: 'Czujnik zbiornika bezodpływowego, który monitoruje na bazie estymacji zajętości jego stan',
            save: 'Zapisz',
            back: 'Powrót',
            position_marker: 'Marker lokalizacji zbiornika',
            position_marker_desc: 'Przybliż mapę do swojej posesji i postaw marker w punkcie w którym operator szambiarki podłącza się do twojego zbiornika.',
            add_money: 'Uzupełnij saldo',
            first_name: 'Imię',
            first_name_required: 'Pole imie nie może być puste',
            last_name: 'Nazwisko',
            last_name_required: 'Pole nazwisko nie może być puste',
            email: 'Email',
            email_required: 'Pole email nie może być puste',
            email_invalid: 'W poplu email proszę wprowadzić wartość w formacie adresu mailowego - np: kowalski@wp.pl',
            phone_no: 'Numer telefonu',
            phone_no_required: 'Numer telefonu jest wymagany',
            phone_no_invalid: 'Numer telefonu jest nieprawidłowy',
            agree_to_terms: 'Zgoda na wykorzystanie powyższych danych osobowych w procesie obsługi monitoringu asenizacyjnego w tym do generacji umów na usługi z firmami asenizacyjnymi',
            agree_to_terms_required: 'Zgoda na przetważanie moich danych',
            owner_info: 'Informacja o właścicielu zbiornika',
            owner_info_desc: 'Dane właściciela zbiornika są uwżywane przy generowaniu wymaganych prawem umów na usługi asenizacyjne - umowy te są dostępne w aplikacji mobilnej',
            notification_info: 'Powiadomienia',
            notification_info_desc: 'Powiadomienia realizowane są w przypadku ważnych zdażeń w procesie obsługi zbiorników bezodpływowych (za niskie saldo, wykonany odbiór odpadów, itp...',
            notification_emails: 'Wprowadź maile na które będziemy wysyłać notyfikacje, możesz wprowadzić ich kilka oddzialając je przecinkami - np: radek@wp.pl, jacek@gmiail.com, itd...',
            address_detail: 'Address Detail',
            address_detail_desc: 'Address Detail Desc',
            city: 'City',
            street_name: 'Street Name',
            flat_no: 'Flat Number',
            select_your_address_desc: 'Select your address Desc',
            required_field: 'Required Field',
            owner_already_assigned: 'Owner Already Assigned',
            error: 'Error',
            info: 'Info',
            notification_settings_updated: 'Notification Settings Updated',
            owner_updated: 'Owner Updated',
            owner_already_assigned_header: "Sensor Taken",
            owner_already_assigned_message: "Owner Already assigned to sensor",
            remove_access_head: "Remove Access!",
            remove_access_desc: "Remove Access Desc",
            remove_access_success: "Remove Access Success",
            user_access_updated_success: "User Access Updated",
            user_invited_success: "User Invited Success",
            close_ticket_success: "Close Ticket Success",
            close_ticket_confirm_message: "Close Ticket message",
            all_fields_are_required: "All Fields are required",
            request_raised_success: "Request Raised Success",
        },
        dashboard: {
            dashboard: 'Start',
            change_selected_company: 'Zmień wybraną firmę',
            route_manager: 'GZO  Mieszkaniec',
            logout: 'Wyloguj',
            orders: 'Zamówienia',
            device_list: 'Lista zbiorników',
            device_list_desc: 'Lista na której widać wszystkie skonfigurowane przez ciebie lub udostępnione tobie zbiorniki.',
            change_selected_car: 'Zmień auto',
            generate_route: 'Generuj trasę',
            welcome_back: 'Witaj ponownie',
            action: 'Akcje',
            close: 'Zamknij',
            yes: 'Tak',
            no: 'Nie',
            complete_pickup: 'Odebrano',
            send: 'Wyślij',
            company_list: 'Lista firm',
            company_list_desc: 'Poniżej wyświetlamy wszystkie firmy w których widniejesz jako pracownik, wybierz firmę w której chcesz obecnie pracować',
            car_list: 'Lista aut',
            car_list_desc: 'Lista samochodów dostępnych w tej firmie, wybierz samochód którym będziesz jechał, jeśli auto jest zajęte przez innego kierowcę to wybierając je przejmujesz realizację trasy na której obecnie to auto się znajduje',
            route_list: 'Lista tras',
            route_list_desc: 'Dostepne dla punkty startowe tras - wybierz jeden uruchom generowanie trasy wokół tego punktu',
            pickup: 'Odbiór',
            general: 'Ogólne',
            map: 'Mapa',
            open: 'Otworz',
            okay: 'Ok',
            action_success: 'Sukces',
            qty_taken: 'Odebrana ilość',
            tank_empty: 'Zbiornik pusty',
            connector: 'Złączka',
            open_google_map_current_point: 'Pokaż trasę na mapie google',
            open_google_map_all_point: 'Pokaż wszystkie punkty na mapie google',
            report_other_problem: 'Zgłoś problem',
            not_able_to_pickup: 'Brak możliwości odbioru, jadę dalej, może wrócę',
            inform_owner: 'Wyślij klientowi informację, żejesteś przy jego posesji, nie masz możliwości odbioru i że czekasz 3 minuty nim pojedziesz dalej',
            open_map: 'Otwórz mapę',
            add_one_more_order: 'Dodaj odbiór do zlecenia',
            move_to_drop_point: 'Powrót do zlewni',
            drop_at_drop_point: 'Potwierdz zrzut',
            report_issue: 'Złoś problem',
            permission_denied: 'Zezwól przeglądarce na korzystanie z twojej lokalizacji',
            pickup_gen_desc: 'Zarejestruj ile metrów pobrałeś ze zbiornika',
            done: 'Zrobione',
            pickup_action_desc: 'Wybierz jedną z akcji poniżej',
            no_data_found: 'Nie znaleziono wpisów',
            more: 'Więcej',
            your_profile: 'Your Profile',
            profile_view_desc: 'Profile View Desc'
        },
        device_detail: {

            share_device: 'Udostępnij zbiornik',
            view_orders: 'Przeglądaj zlecenia',
            view_transactions: 'Przeglądaj płatności',
            detail_heading: 'Szczegóły',
            detail_desc: 'Informacje o wybranym zbiorniku',
            contracts: 'Umowy',
            support_requests: 'Zgłoszenia',
            user_list: 'Uzytkownicy',
            owner_detail: 'Dane właściciela zbiornika',
            notification_settings: 'Ustawienia powiadomień'
        },
        general: {
            support_ticket: 'Zgłoszenie do helpdesku',
            payment_history: 'Historia płatności',
            pickup_history: 'Historia odbiorów / zamówień',
            contracts: 'Umowy asenizacyjne',
            support_request_list: 'Zgłoszenia',
            support_request_detail: 'Przeglądaj zgłoszenia i reklamacje.',
            share_access_list: 'Lista przyznanych dostępów do zbiornika',
            share_access: 'Przyznaj dostęp',
            add_money: 'Uzupełnij saldo',
            no_data_found: 'Nie odnaleziono wpisów',
            payment_list_item: 'Wpłacono: {{amount}}',
            pickup_history_list_item: 'Czas: {{duration}} | Odległość: {{distance}}',
            user_list_item: 'status',
            owner_detail: 'Dane własciciela',
            update: 'Aktualizuj',
            access_edit: 'Edycja uprawnień dostępu',
            remove_access: 'Odbierz dostęp',
            notification_settings: 'Ustawienia powiadomień',
            reply: 'Odpowiedz',
            close_ticket: 'ZAMKNIJ zgłoszenie',
            contracts_desc: 'Contract Desc'
        },
        pickup_information: {
            pickup_information: 'Informacje o odbiorze',
            pickup_info1: 'Twój zbiornik został poprawnie połączony z systemem monitoringu gmina.zintegrowana.online.',
            pickup_info2: 'Pamiętaj, by dbać o to by twoje saldo było wyższe niż wartość odbioru twojego pełnego zbiornika.',
            pickup_info3: 'Wysyłamy automatycznie auta by opróżniały zbiorniki, których saldo jest wystarczające i których estymowana zajętość przekracza 60%.',
            pickup_info4: 'Za odbiór nieczystości płacisz tylko za tyle metrów ile od Ciebie odebrano. Opłata jest pobierana z twojego salda i jeśli saldo jest mniejsze niż warość twojego pełnego zbiornika to wysyłamy tobie przypomnienie o jego uzupełnieniu.',
            pickup_info5: 'Twoje minimalne saldo to:',
            pickup_info6: 'Twoje aktualne saldo to:',
            pickup_info7: 'By być spokojnym, że przyjedziemy opróżnić Twój zbiornik musisz:',
            pickup_info8: 'Podnieść wartość salda na zbiorniku o:',
            pickup_info9: 'Minimalna wartość do zapłaty to:',
            enter_amount_greater_or_equal: 'Wprowadź wartość większą lub równą minimalnej wartości do zapłaty.',
            pay: 'Zapłać'
        },
        pickup_confirmation: {
            pickup_confirmation: 'Potwierdzenie odbioru',
            pickup_info1: 'Skutecznie zamówiono opróżnienie zbiornika, twoje zamówienie to: ({{signatura}})',
            pickup_info2: 'Zablokowaliśmy na twoim saldzie kwotę: - {{minSaldoRequired}} zł ',
            pickup_info3: 'Zablokowana kwota z salda zostanie użyta jako zapłata za odbiór, z zablokowanej kwoty wykorzystamy tylko taką ilość środków, która będzie odpowiadać ilości m3 wypompowanych ze zbiornika.',
            pickup_info4: 'Jeśli zamówiono odbiór pilny bezpośrednio w aplikacji - możesz oczekiwać, odbioru najpóżniej wkolejny dzień roboczy.',
            pickup_info5: 'Nie pracujemy w niedziele',
            pickup_info6: 'W razie problemów wyślemy informację o tym fakcie mailem na adresy zdefiniowane do powiadomień dla Twojego zbiornika.',
            pickup_info7: 'Jeśli kierowca będzie miał problem z dostępem do zbiornika, wyśle informację przez email lub sms - jeśli obsługa smsów zostanie włączona dla zbiornika.',
            pickup_info8: 'Za sms - Twoje saldo będzie obciążone kosztem 0.35 groszy brutto. W przypadku maila notyfikacja jest darmowa',
            continue: 'Dalej'
        },
        support_ticket: {
            type_your_message: 'W tym miejscu wpisz wiadomość',
            subject: 'Temat',
            support_ticket_heading: 'Zgłoszenia',
            support_ticket_desc: 'Możesz wysłać zgłoszenie lub reklamację do helpdesku. Na potrzeby reklamacji opóźniamy zapłatę do firmy asenizacyjnej o 5 dni celem zabezpieczenia się przed pomyłkami w odbiorze. W przypadku jeśli chcecie państwo reklamować usługę po 5 dniach należy to zrobić bezpośrednio do firmy, która dokonywała odbioru. W informacji o zleceniu jest nazwa firmy dokonującej odbioru i w zakładce umowy jest z tą firmą wygenerowana umowa na usługi asenizacyjnej - tam znajdziesz informację procedurze reklamacyjnej - stosuj ją jeśli od odbioru minęło 5 dni',
            send: 'Wyślij',
            reclamation: 'Zgłoszenie reklamacji',
            support: 'Zgłoszenie do helpdesku',
            topic: 'Wybierz typ zgłoszenia pomoc / reklmacja',
        },
        add_money: {
            tank_name_info: 'Zbiornik: {{sensorId}}',
            tank_no_info: 'Numer: {{signatura}}',
            current_balance_info: 'Saldo: {{actualSaldo}}',
            min_balance_info: 'Min saldo: {{minSaldoRequired}}',
            amount: 'Kwota',
            info1: 'Dodaj więcej pieniędzy by podnieść saldo.',
            info2: 'Obsługujemy automatycznie twój zbiornik tylko jeśli saldo jest wyższe niż twoje saldo minimalne.'
        },
        share_access: {
            invite: 'Zaproś',
            email: 'Email',
            name: 'Imię',
            full_name: 'Imię i nazwisko',
            tank_signature: 'Numer zbiornika',
            email_required: 'Email jest wymagany',
            invalid_email: 'Błędny Email',
            user: 'Użytkownik',
            admin: 'Administrator',
            access_level: 'Poziom dostępu',
            update_info1: 'Możesz odebrać dostęp do zarządzania twoim zbiornikiem tej osobie',
            update_info2: 'Możesz rozszerzyć tej osobie dostęp by została administratorem zbiornika tak jak ty. (Administratorzy mogą udostępniać zbiornik innym i nadawać im uprawnienia, mogą również zmienić dane właściciela zbiornika).',
            update_info3: 'Status',
            info1: 'Masz możliwość by udostępnić zbiornik innym osobom.',
            info2: 'Jeśli udostępnisz zbiornik innym będą mogli oni ze swoich telefonów do zamaiania pilnych odbiorów, wykonywanie wpłat na saldo zbiornika.',
            info3: 'Wprowadź poniżej email osoby której chcesz udostępnić zbiornik.',
        },
        support_detail: {
            signature: 'Zapytanie',
            type: 'Typ',
            status: 'Status',
            subject: 'Temat'
        }
    }
};
