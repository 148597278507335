import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { IOfficeData } from '../interfaces/office.interface';
import { MainMenuProvider } from './main-menu.provider';
import { ToastController, LoadingController, AlertController } from '@ionic/angular';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class HelperProvider {

  TOAST_DURATION = 2000;
  TOAST_POSITION: 'top' | 'bottom' | 'middle' = 'bottom';
  userToken: string;

  onEvent: Subject<any>;
  loadingInstance: any;

  constructor(
    private http: HttpClient,
    private mainMenuProvider: MainMenuProvider,
    public toastCtrl: ToastController,
    private alertController: AlertController,
    private router: Router,
    public loadingCtrl: LoadingController
    ) {
    this.onEvent = new Subject();
    this.getUserToken();
  }

  getUserToken() {

    if (window.localStorage.getItem('ngStorage-token')) {
      this.userToken = window.localStorage.getItem('ngStorage-token');
    } else {
      console.log('No token');
    }

  }


  /*
   ********************************************************************************************************************************
   ********************************************************************************************************************************
                                               REGISTER HELPERS STARTS 
   ********************************************************************************************************************************
   ********************************************************************************************************************************
   */

  /**
  * get register list
  */
  getRegisterList(filters?): Promise<any> {
    return new Promise((resolve, reject) => {
      if (JSON.parse(localStorage.getItem('ngStorage-selectedOffice')) && JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))['_id']) {
        filters = filters || {};
        // tslint:disable-next-line:max-line-length
        this.getRequest(`${environment.serverUrl}api/offices/${JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))['_id']}/registries/list?fields={}&filters=${JSON.stringify(filters)}`)
          .then(resolve).catch(reject);
      } else {
        reject('No office Id found');
      }
    });
  }

  /**
   * 
   * @param regId 
   * get the register data from the regId
   */
  getRegisterData(regId: string): Promise<any> {
    return new Promise((resolve, reject) => {
      if (regId) {
        this.getRequest(`${environment.serverUrl}api/reg/${regId}`)
          .then(resolve).catch(reject);
      } else {
        reject('No reg Id provided. Please check configuration or consult admin about it');
      }
    });
  }

  /**
   * 
   * @param regData 
   * create new Register
   */
  createRegisterData(regData: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.postRequest(`${environment.serverUrl}api/reg/new`, regData)
        .then(resolve).catch(reject);
    });
  }

  /**
   * 
   * @param regId 
   * @param regData 
   * update register
   */
  updateRegisterData(regId: string, regData: any): Promise<any> {
    return new Promise((resolve, reject) => {
      if (regId) {
        this.putRequest(`${environment.serverUrl}api/reg/${regId}`, regData)
          .then(resolve).catch(reject);
      } else {
        reject('No reg Id provided. Please check configuration or consult admin about it');
      }
    });
  }

  /**
   * 
   * @param regId 
   * delete register
   */
  deleteRegister(regId: string): Promise<any> {
    return new Promise((resolve, reject) => {
      if (regId) {
        this.deleteRequest(`${environment.serverUrl}api/reg/${regId}`)
          .then(resolve).catch(reject);
      } else {
        reject('No reg Id provided. Please check configuration or consult admin about it');
      }
    });
  }

  /**----------------------------------------------------------------------------------------------------------------------------
    -----------------------------------------------------------------------------------------------------------------------------
                                              REGISTER HELPER ENDS
    -----------------------------------------------------------------------------------------------------------------------------
    -----------------------------------------------------------------------------------------------------------------------------
   */
  /*
   ********************************************************************************************************************************
   ********************************************************************************************************************************
                                               REGISTER RECORD HELPERS STARTS 
   ********************************************************************************************************************************
   ********************************************************************************************************************************
   */

  /**
  * 
  * @param regId 
  * @param recId 
  * get the record data from the register record
  */
  getRegisterRecordData(regId: string, recId: string): Promise<any> {
    return new Promise((resolve, reject) => {
      if (regId && recId) {
        this.getRequest(`${environment.serverUrl}api/reg/${regId}/${recId}`)
          .then(resolve).catch(reject);
      } else {
        reject('No reg Id or recId provided. Please check configuration or consult admin about it');
      }
    });
  }

  /**
   * 
   * @param regId 
   * @param recData 
   * create register record
   */
  createRegisterRecord(regId: string, recData: any): Promise<any> {
    return new Promise((resolve, reject) => {
      if (regId) {
        this.postRequest(`${environment.serverUrl}api/reg/${regId}/new`, recData)
          .then(resolve).catch(reject);
      } else {
        reject('No regId provided. Please check configuration or consult admin about it');
      }
    });
  }

  /**
   * 
   * @param regId 
   * @param recId 
   * @param recData 
   * update register record
   */
  updateRegisterRecord(regId: string, recId: string, recData: any): Promise<any> {
    return new Promise((resolve, reject) => {
      if (regId && recId) {
        this.putRequest(`${environment.serverUrl}api/reg/${regId}/${recId}`, recData)
          .then(resolve).catch(reject);
      } else {
        reject('No regId or recId provided. Please check configuration or consult admin about it');
      }
    });
  }

  deleteRegisterRecord(regId: string, recId: string): Promise<any> {
    return new Promise((resolve, reject) => {
      if (regId && recId) {
        this.deleteRequest(`${environment.serverUrl}api/reg/${regId}/${recId}`)
          .then(resolve).catch(reject);
      } else {
        reject('No reg Id or recId provided. Please check configuration or consult admin about it');
      }
    });
  }

  /**----------------------------------------------------------------------------------------------------------------------------
    -----------------------------------------------------------------------------------------------------------------------------
                                              REGISTER RECORD HELPER ENDS
    -----------------------------------------------------------------------------------------------------------------------------
    -----------------------------------------------------------------------------------------------------------------------------
   */

  /*
   ********************************************************************************************************************************
   ********************************************************************************************************************************
                                               PROCESS HELPERS STARTS 
   ********************************************************************************************************************************
   ********************************************************************************************************************************
   */

  /**
   * 
   * get process list
   */
  getProcessList(): Promise<any> {
    return new Promise((resolve, reject) => {
      if (JSON.parse(localStorage.getItem('ngStorage-selectedOffice')) && JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))['_id']) {
        // tslint:disable-next-line:max-line-length
        this.getRequest(`${environment.serverUrl}api/process.config/list?officeId=${JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))['_id']}&userId=%23%23%23ADMIN%23%23%23`)
          .then(resolve).catch(reject);
      } else {
        reject('No office Id found');
      }
    });
  }

  /**
   * 
   * @param processId 
   * get process data form the process id
   */
  getProcess(processId: string): Promise<any> {
    return new Promise((resolve, reject) => {
      if (processId) {
        this.getRequest(`${environment.serverUrl}api/process.config/${processId}`)
          .then(resolve).catch(reject);
      } else {
        reject('No processId provided. Please check configuration or consult admin about it');
      }
    });
  }

  /**
   * 
   * @param regId 
   * @param recId 
   * get the process buttons.
   */
  getProcessButtons(regId: string, recId: string): Promise<any> {
    return new Promise((resolve, reject) => {
      if (regId && recId) {
        this.getRequest(`${environment.serverUrl}api/reg-process/${regId}/${recId}/getButtons`)
          .then(resolve).catch(reject);
      } else {
        reject('No regId or recId provided. Please check configuration or consult admin about it');
      }
    });
  }

  /**
 * 
 * @param regId 
 * @param recId 
 * @param buttonId 
 * get the process activity data.
 */
  getProcessActivityData(regId: string, recId: string, buttonId: any): Promise<any> {
    return new Promise((resolve, reject) => {
      if (regId && recId) {
        this.postRequest(`${environment.serverUrl}api/reg-process/${regId}/${recId}/${buttonId}`, {})
          .then(resolve).catch(reject);
      } else {
        reject('No regId or recId provided. Please check configuration or consult admin about it');
      }
    });
  }

  /**
   * 
   * @param processData 
   * create new process
   */
  createProcess(processData: any): Promise<any> {
    return null;
  }

  /**
   * 
   * @param processId 
   * @param updatedData 
   * update Process
   */
  updateProcess(processId: string, updatedData: any): Promise<any> {
    return null;
  }

  /**
   * 
   * @param processId 
   * delete process
   */
  deleteProcess(processId: string): Promise<any> {
    return null;
  }

  /**----------------------------------------------------------------------------------------------------------------------------
    -----------------------------------------------------------------------------------------------------------------------------
                                              PROCESS HELPER ENDS
    -----------------------------------------------------------------------------------------------------------------------------
    -----------------------------------------------------------------------------------------------------------------------------
   */



  /*
   ********************************************************************************************************************************
   ********************************************************************************************************************************
                                               INSTANCE HELPERS STARTS 
   ********************************************************************************************************************************
   ********************************************************************************************************************************
   */

   /**
   * 
   * @param processId 
   * get process instance data..
   */
  getProcessInstanceList(processId: string): Promise<any> {
    return new Promise((resolve, reject) => {
      if (processId) {
        if (JSON.parse(localStorage.getItem('ngStorage-selectedOffice')) && JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))['_id']) {
          this.getRequest(`${environment.serverUrl}api/process/instances/groupBy/${processId}`)
            .then(resolve).catch(reject);
        } else {
          reject('No office Id found');
        }
      } else {
        reject('No ProcessId or instanceId provided. Please check configuration or consult admin about it');
      }
    });
  }

  /**
   * 
   * @param processId 
   * @param instanceId 
   * get process instance data..
   */
  getProcessInstance(processId: string, instanceId: string): Promise<any> {
    return new Promise((resolve, reject) => {
      if (processId && instanceId) {
        if (JSON.parse(localStorage.getItem('ngStorage-selectedOffice')) && JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))['_id']) {
          this.getRequest(`${environment.serverUrl}api/offices/${JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))['_id']}/process/${processId}/instances/${instanceId}`)
            .then(resolve).catch(reject);
        } else {
          reject('No office Id found');
        }
      } else {
        reject('No ProcessId or instanceId provided. Please check configuration or consult admin about it');
      }
    });
  }

  /**----------------------------------------------------------------------------------------------------------------------------
    -----------------------------------------------------------------------------------------------------------------------------
                                              INSTANCE HELPER ENDS
    -----------------------------------------------------------------------------------------------------------------------------
    -----------------------------------------------------------------------------------------------------------------------------
   */



  /*
   ********************************************************************************************************************************
   ********************************************************************************************************************************
                                               FILES AND DOCUMENT HELPERS STARTS 
   ********************************************************************************************************************************
   ********************************************************************************************************************************
   */



  /**
   * 
   * @param documentId 
   * get the document data..
   */
  getDocument(documentId: string): Promise<any> {
    return new Promise((resolve, reject) => {
      if (documentId) {
        this.getRequest(`${environment.serverUrl}api/files/${documentId}/getBase64File`)
          .then(resolve).catch(reject);
      } else {
        reject('No documentId provided. Please check configuration or consult admin about it');
      }
    });
  }

  /**
   * 
   * @param regId 
   * @param recId 
   * @param formData 
   * upload file to server...
   */
  uploadFile(regId: string, recId: string, formData: FormData): Promise<any> {
    return new Promise((resolve, reject) => {
      // get other info...
      let officeId;
      if (JSON.parse(localStorage.getItem('ngStorage-selectedOffice')) && JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))['_id']) {
        officeId = JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))['_id'];
        const profile = (localStorage.getItem('ngStorage-profile')) ? JSON.parse(localStorage.getItem('ngStorage-profile')) : null;

        if (profile) {
          if (regId && recId && formData) {
            // tslint:disable-next-line:max-line-length
            this.postRequest(`${environment.serverUrl}api/files/${officeId}/upload?creatorId=${profile['_id']}&creatorLogin=${profile['login']}&officeId=${officeId}&recId=${recId}&regId=${regId}`, formData)
              .then(resolve).catch(reject);
          } else {
            reject('No regId/recId provided. Please check configuration or consult admin about it');
          }
        } else {
          reject('No profile Data');
        }
      } else {
        // no office..
        reject('No Office Id');
      }
    });
  }


  

  /**----------------------------------------------------------------------------------------------------------------------------
    -----------------------------------------------------------------------------------------------------------------------------
                                              FILES AND DOCUMENT HELPER ENDS
    -----------------------------------------------------------------------------------------------------------------------------
    -----------------------------------------------------------------------------------------------------------------------------
   */

  /*
   ********************************************************************************************************************************
   ********************************************************************************************************************************
                                               SCANQUE HELPERS STARTS 
   ********************************************************************************************************************************
   ********************************************************************************************************************************
   */


  /**----------------------------------------------------------------------------------------------------------------------------
    -----------------------------------------------------------------------------------------------------------------------------
                                              SCANQUE HELPER ENDS
    -----------------------------------------------------------------------------------------------------------------------------
    -----------------------------------------------------------------------------------------------------------------------------
   */

  /*
   ********************************************************************************************************************************
   ********************************************************************************************************************************
                                               FORM HELPERS STARTS 
   ********************************************************************************************************************************
   ********************************************************************************************************************************
   */



  /**
   * 
   * get the template list..
   */
  getFormList(): Promise<any> {
    return new Promise((resolve, reject) => {
      if (JSON.parse(localStorage.getItem('ngStorage-selectedOffice')) && JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))['_id']) {
        this.getRequest(`${environment.serverUrl}api/offices/${JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))['_id']}/form/list`)
          .then(resolve).catch(reject);
      } else {
        reject('No office Id found');
      }
    });
  }

  /**
   * 
   * @param formId 
   * GET the form from the form id
   */
  getForm(formId: string): Promise<any> {
    return new Promise((resolve, reject) => {
      if (formId) {
        if (JSON.parse(localStorage.getItem('ngStorage-selectedOffice')) && JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))['_id']) {
          this.getRequest(`${environment.serverUrl}api/offices/${JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))['_id']}/forms/${formId}`)
            .then(resolve).catch(reject);
        } else {
          reject('No office Id found');
        }
      } else {
        reject('No form Id provided. Please check configuration or consult admin about it');
      }
    });
  }

  // create form
  createForm(formData: any): Promise<any> {
    return new Promise((resolve, reject) => {
      if (JSON.parse(localStorage.getItem('ngStorage-selectedOffice')) && JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))['_id']) {
        this.postRequest(`${environment.serverUrl}api/offices/${JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))['_id']}/forms/new`, formData)
          .then(resolve).catch(reject);
      } else {
        reject('No office Id found');
      }
    });
  }

  /**
* 
* @param formId 
* GET the form from the form id
*/
  updateForm(formId: string, formData: any): Promise<any> {
    return new Promise((resolve, reject) => {
      if (formId) {
        if (JSON.parse(localStorage.getItem('ngStorage-selectedOffice')) && JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))['_id']) {
          this.putRequest(`${environment.serverUrl}api/offices/${JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))['_id']}/forms/${formId}`, formData)
            .then(resolve).catch(reject);
        } else {
          reject('No office Id found');
        }
      } else {
        reject('No form Id provided. Please check configuration or consult admin about it');
      }
    });
  }


  /**----------------------------------------------------------------------------------------------------------------------------
    -----------------------------------------------------------------------------------------------------------------------------
                                              FORM HELPER ENDS
    -----------------------------------------------------------------------------------------------------------------------------
    -----------------------------------------------------------------------------------------------------------------------------
   */



  /*
   ********************************************************************************************************************************
   ********************************************************************************************************************************
                                               TEMPLATE HELPERS STARTS 
   ********************************************************************************************************************************
   ********************************************************************************************************************************
   */



  /**
   * 
   * get the template list..
   */
  getTemplateList(): Promise<any> {
    return new Promise((resolve, reject) => {
      if (JSON.parse(localStorage.getItem('ngStorage-selectedOffice')) && JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))['_id']) {
        this.getRequest(`${environment.serverUrl}api/offices/${JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))['_id']}/templates/list`)
          .then(resolve).catch(reject);
      } else {
        reject('No office Id found');
      }
    });
  }


  /**
   * 
   * @param templateId 
   * get the template data..
   */
  getTemplate(templateId: string): Promise<any> {
    return new Promise((resolve, reject) => {
      if (templateId) {
        if (JSON.parse(localStorage.getItem('ngStorage-selectedOffice')) && JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))['_id']) {
          this.getRequest(`${environment.serverUrl}api/offices/${JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))['_id']}/templates/${templateId}`)
            .then(resolve).catch(reject);
        } else {
          reject('No office Id found');
        }
      } else {
        reject('No templateId provided. Please check configuration or consult admin about it');
      }
    });
  }


  /**
   * 
   * @param templateData
   * create the template data..
   */
  createTemplate(templateData: any): Promise<any> {
    return new Promise((resolve, reject) => {
      if (templateData) {
        if (JSON.parse(localStorage.getItem('ngStorage-selectedOffice')) && JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))['_id']) {
          this.postRequest(`${environment.serverUrl}api/offices/${JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))['_id']}/templates/new`, templateData)
            .then(resolve).catch(reject);
        } else {
          reject('No office Id found');
        }
      } else {
        reject('No templateId provided. Please check configuration or consult admin about it');
      }
    });
  }

  /**
      * 
      * @param templateData
      * update the template data..
      */
  updateTemplate(templateId, updatedTemplateData: any): Promise<any> {
    return new Promise((resolve, reject) => {
      if (updatedTemplateData) {
        if (JSON.parse(localStorage.getItem('ngStorage-selectedOffice')) && JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))['_id']) {
          // tslint:disable-next-line:max-line-length
          this.putRequest(`${environment.serverUrl}api/offices/${JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))['_id']}/templates/${templateId}`, updatedTemplateData)
            .then(resolve).catch(reject);
        } else {
          reject('No office Id found');
        }
      } else {
        reject('No templateId provided. Please check configuration or consult admin about it');
      }
    });
  }


  /**----------------------------------------------------------------------------------------------------------------------------
    -----------------------------------------------------------------------------------------------------------------------------
                                              TEMPLATE HELPER ENDS
    -----------------------------------------------------------------------------------------------------------------------------
    -----------------------------------------------------------------------------------------------------------------------------
   */


  /**
   * 
   * get the profile data..
   */
  getProfileData(profileId?: string): Promise<any> {
    return new Promise((resolve, reject) => {
      if ((JSON.parse(window.localStorage.getItem('ngStorage-profile')) && JSON.parse(window.localStorage.getItem('ngStorage-profile'))['profileId'])) {
        this.getRequest(`${environment.serverUrl}api/profile/${JSON.parse(window.localStorage.getItem('ngStorage-profile'))['profileId']}`)
          .then(resolve).catch(reject);
      } else if (profileId) {
        this.getRequest(`${environment.serverUrl}api/profile/${profileId}`)
        .then(resolve).catch(reject);
      } else {
        reject('No documentId provided. Please check configuration or consult admin about it');
      }
    });
  }

  // // update profile data 
  // updateProfileData(updatedData) {
  //   return new Promise((resolve, reject) => {
  //     if (JSON.parse(localStorage.getItem('ngStorage-profile')) && JSON.parse(localStorage.getItem('ngStorage-profile'))['profileId']) {
  //       this.http.patch(`${environment.serverUrl}api/profile/${JSON.parse(localStorage.getItem('ngStorage-profile'))['profileId']}`, updatedData)
  //       .subscribe((data: Response) => {
  //         this.buildBackendResponse(data, resolve, reject);
  //       }, err => this.buildHttpError(err, reject));
  //     } else {
  //       reject('No Profile id found');
  //     }
  //   });
  // }


  /**
   * updatedDoc: any
   * to update the office doc
   */
  updateOfficeDoc(updatedDoc: any): Promise<any> {
    return new Promise((resolve, reject) => {
      if (JSON.parse(localStorage.getItem('ngStorage-selectedOffice')) && JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))['_id']) {
        this.patchRequest(`${environment.serverUrl}api/offices/${JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))['_id']}`, updatedDoc)
          .then(resolve).catch(reject);
      } else {
        reject('No office Id found');
      }
    });
  }

  // update the office doc in localstorage
  updateLocalStorageOfficeDoc(updatedDoc) {
    if (JSON.parse(localStorage.getItem('ngStorage-selectedOffice')) && JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))['_id']) {
      updatedDoc['_id'] = JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))['_id'];
      localStorage.setItem('ngStorage-selectedOffice', JSON.stringify(updatedDoc));
    }
  }


  //  ************************ OTHER HELPERS ********************************* //


  dataTableRequest(regId: string, params: any): Promise<any> {
    return new Promise((resolve, reject) => {
      try {
        this.getUserToken();
        const headers = new HttpHeaders({
          'Authorization': this.userToken,
          'officeId': window.localStorage.getItem('selectedOfficeId') || ''
        });
        if (regId && params) {
          this.http.post(`${environment.serverUrl}api/reg/datatableNgx/${regId}`, params, { headers })
            .subscribe((data) => {
              if (data['success']) {
                resolve(data);
              } else {
                reject('Error Occurred while getting the data');
              }
            }, err => this.buildHttpError(err, reject));
        } else {
          reject('Error occurred while updating the document');
        }
      } catch (err) {
        reject(err);
      }
    });
  }
  
  dataTableRequestV2(regId: string, params: any, headersDoc = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      try {
        this.getUserToken();
        const headers = new HttpHeaders({
          'Authorization': this.userToken,
          'officeId': window.localStorage.getItem('selectedOfficeId') || '',
          ...headersDoc
        });
        if (regId && params) {
          this.http.post(`${environment.serverUrl}api/reg/${regId}/data-table?platform=mobile`, params, { headers })
          // this.http.post(`${environment.serverUrl}api/registries/${regId}/record-list/dttable/v2`, params, { headers })
            .subscribe((data) => {
              if (data) {
                resolve(data);
              } else {
                reject('Error Occurred while getting the data');
              }
            }, err => this.buildHttpError(err, reject));
        } else {
          reject('Register Id or params not found');
        }
      } catch (err) {
        reject(err);
      }
    });
  }


  getRequest(getUrl: string): Promise<any> {
    return new Promise((resolve, reject) => {
      try {
        this.getUserToken();
        const headers = new HttpHeaders({
          'Authorization': this.userToken,
          'officeId': window.localStorage.getItem('selectedOfficeId') || ''
        });
        this.http.get(getUrl, { headers })
          .subscribe((data: Response) => {
            this.buildBackendResponse(data, resolve, reject);
          }, err => this.buildHttpError(err, reject));
      } catch (err) {
        reject(err);
      }
    });
  }


  patchRequest(getUrl: string, updatedData): Promise<any> {
    return new Promise((resolve, reject) => {
      try {
        this.getUserToken();
        const headers = new HttpHeaders({
          'Authorization': this.userToken,
          'officeId': window.localStorage.getItem('selectedOfficeId') || ''
        });
        if (getUrl && updatedData) {
          this.http.patch(getUrl, updatedData, { headers })
            .subscribe((data) => {
              this.buildBackendResponse(data, resolve, reject);
            }, err => this.buildHttpError(err, reject));
        } else {
          reject('Error occurred while updating the document');
        }
      } catch (err) {
        reject(err);
      }
    });
  }

  putRequest(getUrl: string, updatedData): Promise<any> {
    return new Promise((resolve, reject) => {
      try {
        this.getUserToken();
        const headers = new HttpHeaders({
          'Authorization': this.userToken,
          'officeId': window.localStorage.getItem('selectedOfficeId') || ''
        });
        if (getUrl && updatedData) {
          this.http.put(getUrl, updatedData, { headers })
            .subscribe((data) => {
              this.buildBackendResponse(data, resolve, reject);
            }, err => this.buildHttpError(err, reject));
        } else {
          reject('Error occurred while updating the document');
        }
      } catch (err) {
        reject(err);
      }
    });
  }

  
  async getMyData(data) {
    return await this.postRequest(`${environment.serverUrl}api/get-my-data`, data);
  }

  postRequest(getUrl: string, updatedData): Promise<any> {
    return new Promise((resolve, reject) => {
      try {
        this.getUserToken();
        const headers = new HttpHeaders({
          'Authorization': this.userToken,
          'officeId': window.localStorage.getItem('selectedOfficeId') || ''
        });
        if (getUrl && updatedData) {
          this.http.post(getUrl, updatedData, { headers })
            .subscribe((data) => {
              this.buildBackendResponse(data, resolve, reject);
            }, err => this.buildHttpError(err, reject));
        } else {
          reject('Error occurred while updating the document');
        }
      } catch (err) {
        reject(err);
      }
    });
  }

  deleteRequest(deleteUrl: string): Promise<any> {
    return new Promise((resolve, reject) => {
      try {
        this.getUserToken();
        const headers = new HttpHeaders({
          'Authorization': this.userToken,
          'officeId': window.localStorage.getItem('selectedOfficeId') || ''
        });
        this.http.delete(deleteUrl, { headers })
          .subscribe((data: Response) => {
            this.buildBackendResponse(data, resolve, reject);
          }, err => this.buildHttpError(err, reject));
      } catch (err) {
        reject(err);
      }
    });
  }

  /**
   * 
   * @param data 
   * @param resolve 
   * @param reject 
   * build the success or error message from the response
   */
  buildBackendResponse(data, resolve, reject) {
    if (data && data['success']) {
      if (data['result']) {
        resolve(data['result']);
      } else {
        reject('Response is empty. Please check the configuration');
      }
    } else {
      reject((data && data['error'] && data['error']['txt']) || 'Some error occured');
    }
  }

  /**
   * 
   * @param err 
   * @param reject 
   * build the error message from the http error
   */
  buildHttpError(err, reject) {
    const tempError = ((err['error']  && err['error']['message']) || (err && err['status'] && err['message']) || (err['status'] + '-' + err['message']))
    console.log("error = ", err, tempError);
    if (err && err['status'] && err['error'] && err['error']['message']) {
      if (tempError === "jwt expired") {
        this.router.navigateByUrl("/login");
        return reject("Your Login is expired, Login again");
      }
      reject((err['error']  && err['error']['message']) || (err['status'] + '-' + err['message']));
    } else {
      reject('Error Occured while fetching the data');
    }
  }

  /**
   * 
   * @param docInfo 
   * 
   * download the document
   */
  downloadDocument(docInfo) {
    try {
      const a = document.createElement('a');
      const blob = this.b64toBlob(docInfo['base64'], docInfo['mimetype'], null);
      const blobUrl = URL.createObjectURL(blob);
      a.href = blobUrl;
      a.download = docInfo['originalname'];
      a.click();
    } catch (err) {
      console.log(err);
    }
  }

  /**
   * helper function for document download
   */
  b64toBlob(b64Data, contentType, sliceSize) {
    try {
      contentType = contentType || '';
      sliceSize = sliceSize || 512;
      const byteCharacters = atob(b64Data);
      const byteArrays = [];
      for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);
        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }
      const blob = new Blob(byteArrays, { type: contentType });
      return blob;
    } catch (err) {
      console.log(err);
    }
  }


/**
 * 
 * get format Main menu
 */
  getMainMenu(lang?): Promise<any> {
    return new Promise((resolve, reject) => {
      if (lang) {
        this.mainMenuProvider.currentLang = lang || 'en';
      }
      return resolve([]);
      this.getMainMenuData()
        .then((mainMenu) => {
          return this.mainMenuProvider.getMenuItems(mainMenu);
        })
        .then((formattedMenuItems) => {
          resolve(formattedMenuItems || []);
        }).catch(err => {
          console.log(err);
          reject(err);
        });
    });
  }

  /**
* 
* get Main menu Data only
*/
  getMainMenuData(): Promise<any> {
    return new Promise((resolve, reject) => {
      if (JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))) {
        const officeData: IOfficeData = JSON.parse(localStorage.getItem('ngStorage-selectedOffice'));
        if (officeData && officeData.apps && officeData.apps.mainMenu && officeData.apps.mainMenu.id) {
          this.http.get(`${environment.serverUrl}api/offices/${officeData['_id']}/apps/${officeData.apps.mainMenu.id}/main-menu`)
            .subscribe((data: Response) => {
              this.buildBackendResponse(data, resolve, reject);
            }, err => this.buildHttpError(err, reject));
        } else {
          reject('office menu id not found');
        }
      } else {
        reject('No office Id provided. Please check configuration or consult admin about it');
      }
    });
  }

  showToast(message: string, position?: 'top' | 'bottom' | 'middle', duration?: number, showCloseButton?: boolean, closeButtonText?: string) {
    this.toastCtrl.create({
      message: message || 'Toast message',
      duration: duration || this.TOAST_DURATION,
      position: position || this.TOAST_POSITION,
      // showCloseButton: showCloseButton || false,
      // closeButtonText: closeButtonText || 'Okay'
    }).then(temp => {
      temp.present();
    }).catch(err => {
      console.log(err);
    });
  }

  async showLoading(message) {
    const temp = await this.loadingCtrl.create({
      message: message || 'Please wait...'
    });
    temp.present();
    return temp;
  }

  //  ************************ OTHER HELPERS ENDS ********************************* //

  sortArray(data: any[]) {
    return data.sort((a, b) => {
      if (a && b && a.sort > a.sort) {
        return 1;
      } else if (a && b && a.sort < a.sort) {
        return -1;
      } else {
        return 0;
      }
    });
  }

   
  transformSort(array: any[], field: string): any[] {
    if (!array || array && array.length === 0) {
    return [];
    }
    array.sort((a: any, b: any) => {
      if (a && b && a[field] < b[field]) {
        return -1;
      } else if (a && b && a[field] > b[field]) {
        return 1;
      } else {
        return 0;
      }
    });
    return array;
  }


  async startLoading(message = 'Loading...') {
    if (!this.loadingInstance) {
        this.loadingInstance = await this.loadingCtrl.create({
            message,
            // duration: 3000,
          });

          this.loadingInstance.present();
    }
  }

  async closeLoading() {
      if (this.loadingInstance) {
          await this.loadingInstance.dismiss()
          this.loadingInstance = null;
      }
  }

  
  async showAlert(header: any, message: any) {
    return new Promise(async (resolve, reject) => {
      const alert = await this.alertController.create({
        header,
        message,
        buttons: [
          {
            text: 'Cancel',
            role: 'cancel',
            handler: () => {
              resolve(null);
            }
          },
          {
            text: 'OK',
            handler: (data) => {
              resolve(true);
            },
          },
        ],
      });

      await alert.present();
    });
  }

  async getCommentPrompt(message: any) {
    return new Promise(async (resolve, reject) => {
      const alert = await this.alertController.create({
        header: message,
        inputs: [
          {
            name: 'comment',
            type: 'textarea',
            placeholder: message
          }
        ],
        buttons: [
          {
            text: 'Cancel',
            role: 'cancel',
            handler: () => {
              resolve(null);
            }
          },
          {
            text: 'OK',
            handler: (data) => {
              resolve(data?.comment);
            },
          },
        ],
      });

      await alert.present();
    });
  }


}
