export const locale = {
    lang: 'en',
    data: {
        basic: {
            save: 'Save',
            cancel: 'Cancel',
            done: 'Done',
            edit:  'Edit',
            close: 'Close',
            next: 'Next',
            version: 'Version',
            env: 'Env',
            search: 'Search',
        },
        auth: {
            login_heading: 'Login',
            email: 'Email',
            email_required: 'Email is required',
            password: 'Password',
            password_required: 'Password is required',
            login: 'Login',
            signup_heading: 'Signup',
            signup_here: 'Signup Here',
            forgot_password: 'Forgot Password?',
            confirm_password: 'Confirm Password',
            confirm_pass_required: 'Confirm Password is required',
            confirm_pass_not_match: 'Confirm Password didn\'t match.',
            already_have_account: 'Already Have Account?',
            register: 'Register',
            forgot_password_heading: 'Forgot Password',
            send_mail: 'Send Email'

        },
        menu: {
            office: 'Office',
            office_list: 'Office List',
            issue_reporter: 'Issue Reporter',
            logout: 'Logout'
        },
        record: {
            add_new: 'Add New',
            select_your_address: 'Select Your Address',
            cancel: 'Cancel',
            pay: 'Pay',
            tank_capacity: 'Tank Capacity',
            search_address: 'Search Address',
            no_records_found: 'No Record Found',
            create_new: 'Create New',
            selected_address: 'Selected Address',
            continue: 'Continue',
            sensor_id: 'Sensor Id',
            sensor_id_desc: 'Sensor that estimate actual level of available capacity in your septic tank',
            tank_capacity_desc: 'Total size of your tank capacity',
            distance_from_road: 'Distance From Road',
            distance_from_road_desc: 'How long in meters septic service need to use pipe from parked car to tank entry / connector',
            how_to_access_tank_desc: 'There are any dyficulties with access to tank without client help?',
            how_to_access_tank_desc_desc: 'Please describe if there are any limitations with access to your tank. (closed gate, some building works etc...)',
            urgent_order: 'Urgent Order',
            connector: 'Connector',
            entry: 'Entry',
            connector_desc: ' Is your tank equiped with connector or to get access to it need to be open entry?',
            sensor_name: 'Sensor Name',
            sensor_name_desc: 'Sensor Name Desc',
            save: 'Save',
            back: 'Back',
            position_marker: 'Position Marker',
            position_marker_desc: 'Position Marker Desc',
            add_money: 'Add Money',
            first_name: "First Name",
            first_name_required: "First Name is Required",
            last_name: "Last Name",
            last_name_required: "Last Name is Required",
            email: "Email",
            email_required: "Email is Required",
            email_invalid: "Email is Invalid",
            phone_no: "Phone Number",
            phone_no_required: "Phone Number is Required",
            phone_no_invalid: "Phone Number is Invalid",
            agree_to_terms: "Agree to use these details on contract",
            agree_to_terms_required: "You must agree to use these details on contract",
            owner_info: "Owner Info",
            owner_info_desc: "Owner Info Desc",
            notification_info: "Notification Info",
            notification_info_desc: "Notification Info Desc",
            notification_emails: "Notification Emails (Comma Separated)"
        },
        dashboard: {
            dashboard: 'Dashboard',
            change_selected_company: 'Change Selected Company',
            route_manager: 'G.Z.O. Client',
            logout: 'Logout',
            orders: 'Orders',
            device_list: 'Device List',
            device_list_desc: 'Device List Desc',
            change_selected_car: 'Change Selected Car',
            generate_route: 'Generate Route',
            welcome_back: 'Welcome Back',
            action: 'Action',
            close: 'Close',
            yes: 'Yes',
            no: 'No',
            complete_pickup: 'Complete Pickup',
            send: 'Send',
            company_list: 'Company List',
            company_list_desc: 'Company List Desc',
            car_list: 'Car List',
            car_list_desc: 'Car List Desc',
            route_list: 'Route List',
            route_list_desc: 'Route List desc',
            pickup: 'Pickup',
            general: 'General',
            map: 'Map',
            open: 'Open',
            okay: 'Okay',
            action_success: 'Action Success',
            qty_taken: 'Quantity Taken',
            tank_empty: 'Tank Empty',
            connector: 'Connector',
            open_google_map_current_point: 'Open Google Map To get This point',
            open_google_map_all_point: 'Google Map that shows all points',
            report_other_problem: 'Report other problem - Open text area - to put the problem',
            not_able_to_pickup: 'Not able to pickup, Move Pickup to last position in route',
            inform_owner: 'Inform owner That you are not able to pickup',
            open_map: 'Open Map',
            add_one_more_order: 'Add One more order to route',
            move_to_drop_point: 'Start Moving to drop point',
            drop_at_drop_point: 'Drop at Drop Point',
            report_issue: 'Report Issue',
            pickup_gen_desc: 'Pickup Description',
            done: 'Done',
            pickup_action_desc: 'Pickup Action Description',
            permission_denied: 'Location Permission Denied, Enable it to continue using app',
            no_data_found: 'No Data Found',
            more: 'More',

        },
        device_detail: {

            share_device: 'Share Device',
            view_orders: 'View Orders',
            view_transactions: 'View Transactions',
            detail_heading: 'Details',
            detail_desc: 'Details Desc',
            contracts: "Contracts",
            support_requests: "Support Requests",
            user_list: "User List",
            owner_detail: "Owner Detail",
            notification_settings: "Notification Settings"
        },
        general: {
            support_ticket: "Support Ticket",
            payment_history: "Payment History",
            pickup_history: "Pickup History",
            contracts: "Contracts",
            support_request_list: "Support Requests",
            support_request_detail: "Support Request Detail",
            share_access_list: "Share Access List",
            share_access: "Share Access",
            add_money: "Add Money",
            no_data_found: "No Data Found",
            payment_list_item: "Paid: {{amount}}",
            pickup_history_list_item: "Duration: {{duration}} | Distance: {{distance}}",
            user_list_item: "status",
            owner_detail: "Owner Detail",
            update: "Update",
            access_edit: "Access Edit",
            remove_access: "Remove Access",
            notification_settings: "Notification Settings",
            reply: "Reply",
            close_ticket: "Close Ticket"
        },
        pickup_information: {
            pickup_information: 'Pickup Information',
            pickup_info1: "You tank is succesfully connected to gmina.zintegrowana.online monitoring system.",
            pickup_info2: "Last thing you have to do is keep your saldo balance on the level of your tank size payment.",
            pickup_info3: "We sending our cars to pickup your tank only if your saldo is bigger than minimum saldo for your tank.",
            pickup_info4: "You can pay upfront more money to avoid doing payments after each pickup",
            pickup_info5: "Your minimum saldo is:",
            pickup_info6: "Your actual saldo is:",
            pickup_info7: "To be save that we will send car to your you have to:",
            pickup_info8: "Increase your actural saldo by:",
            pickup_info9: "Min amount required to pay:",
            enter_amount_greater_or_equal: "Enter Amount Greated than or Equal to min required amount",
            pay: "Pay"
        },
        pickup_confirmation: {
            pickup_confirmation: 'Pickup Confirmation',
            pickup_info1: "You succsefully ordered new pickup from your tank ({{signatura}})",
            pickup_info2: "We blocked from your saldo balance - {{minSaldoRequired}} zł ",
            pickup_info3: "Your blocked saldo will be use for payment for ordered pickup and we will consume only this amount of money that will be related to number of meters that will be pumped out from your tank.",
            pickup_info4: "As urgent pickup - you can expect that our service even today if your order is done before 8 morning or tomorrow if order is done after 8 morning.",
            pickup_info5: "We working only in working days (no sundays)",
            pickup_info6: "In case of problems we will send you email.",
            pickup_info7: "Id driver will be have problem to access the tank - he will send you sms and will try to put your pickup at end of his route to give you chance to help him access the tank. ",
            pickup_info8: "For sms - your saldo will be charged 0.35 pln ",
            continue: "Continue"
        },
        support_ticket: {
            type_your_message: "Type Your Message Here.",
            subject: "Subject *",
            support_ticket_heading: "Support Heading",
            support_ticket_desc: "Support Desc",
            send: "Send",
            reclamation: "Reclamation",
            support: "Support",
            topic: "Topic *",
        },
        add_money: {
            tank_name_info: "Tank: {{sensorId}}",
            tank_no_info: "No: {{signatura}}",
            current_balance_info: "Saldo: {{actualSaldo}}",
            min_balance_info: "Min saldo: {{minSaldoRequired}}",
            amount: "Amount",
            info1: "Add more money to increase your tank saldo.",
            info2: "We service your tank only if your saldo is above saldo"
        },
        share_access: {
            invite: "Invite",
            email: "Email",
            name: "Name",
            tank_signature: "Tank Signature",
            email_required: "Email is Required",
            invalid_email: "Invalid Email",
            user: "User",
            admin: "Admin",
            access_level: "Access Level",
            update_info1: "You can take back access to manage your tank for this person",
            update_info2: "You can also exctend this person access to become another tank owner (tank owners can invite and promote other users access for owned tank.",
            update_info3: "Status",
            info1: "You have ability to share access to your tank management to another people.",
            info2: "If your share access to them they will be have access to request urgent order or do payment for your tank saldo throu their own application.",
            info3: "Pass bellow email address to person you like to add to your tank as manager.",
        },
        support_detail: {
            signature: "Request",
            type: "Type",
            status: "Status",
            subject: "Subject"
        }
    }
};
