// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // serverUrl: 'https://api.w4.pinbox24.com/',
  // serverUrl: 'https://api.w3.pinbox24.com/',
  apiKey: "AIzaSyBFsPmLE2vm5ZD48xN7ZWws6MP8SHjM3Ug",
  // serverUrl: 'https://api.w4.pinbox24.com/',
  serverUrl: 'https://v42-stage-test.dev.eat.pl/',
  // serverUrl: 'http://localhost:3001/',
  androidSenderId: '988916405168',
  oneSignalAppId: '193b89fd-4b08-4a89-910c-adee9633799a',
  SEPTIC_OFFICE_ID: "64faefc488863301c2d53f93",
  PAYMENT_REG_ID: "65c229954c8874002ca9338d",
  ORDER_REG_ID: "64fb110f88863301c2d54e34",
  CONTRACT_REG_ID: "654384cc81fc84002ecd50ca",
  SUPPORT_REQ_REG_ID: "653fa1c811e7ac002601b5b4",
  SENSOR_USER_REG_ID: "653a5390fa2fae002cbb9d39",
  SENSOR_REG_ID: "653a5390fa2fae002cbb9d39",
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
