import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class MainMenuProvider {
    public model: any[];
    public selectedOffice: string;
    public selectedOfficeObject: Object;
    public isAdmin = false;
    public officeId: string;
    public menuId: string;
    public currentLang: string;
    public langIndex: object = {
        en: 0,
        pl: 1
    };

    urlMapping = {
        'app.office_dashboard': '/device-list',
        'app.customregistries.registriesList': '/register-list',
        'app.processInstances.dashboards': '/processes',
        'app.dataAgregationAppData.dashboards': '/analytical-panel',
        'app.globalCalendar': '/calendar-app',
        'app.pinboxOfficeContacts2.contacts': '/address-book',
        'app.office_alerts': '/alerts',
        'app.settings': '/application-settings',
        'storageManager': '/storage-manager',
        'app.pinboxProcess.officeProcesses': '/process-admin',
    };

    constructor(private http: HttpClient) {
        this.model = [];
        this.currentLang = 'en';
    }
    
    public getMenuItems(data): Promise<any> {
        this.model = [];
        return new Promise((resolve, reject) => {
            this.selectedOffice = localStorage.getItem('ngStorage-selectedOffice');
            if (this.selectedOffice) {
                this.selectedOfficeObject = JSON.parse(this.selectedOffice);
                this.officeId = this.selectedOffice['_id'];
                    this.model = [];
                        if (data) {
                            let menuList: Array<object> = data;
                            menuList = menuList.sort((a, b) => {
                                return (a && b && a['weight'] > b['weight']) ? 1 : ((a && b && b['weight'] > a['weight']) ? -1 : 0);
                            });
                            menuList.forEach(element => {
                                if (element && element['navigation'] && element['mobile'] && !element['navigation'].includes('.')) {
                                    // tslint:disable-next-line:prefer-const
                                    let tempObj = {
                                        'id'       : element['type'],
                                        'title'    : element['title'],
                                        // tslint:disable-next-line:max-line-length
                                        'translate': (element['translations'] && element['translations'][this.langIndex[this.currentLang]][this.currentLang] || element['title']),
                                        'type'     : 'group',
                                        // 'icon'     : (element['icon'])? element['icon'].replace('icon', 'mdi'): '',
                                        'mobile_icon'     : (element['mobile_icon']) ? element['mobile_icon'] : '',
                                        'registerParams': element['registerParams'],
                                        'children': [],
                                        'sort': element['weight'],
                                        'params': {}
                                    };


                                    menuList.forEach(ele => {

                                        if (ele && ele['navigation'] && ele['mobile'] && ( ele['navigation'].split('.').length >= 3 ||  ele['subGroup'] ) ) {
                                            if (ele['subGroup']) {
                                                const SubGroup  = {
                                                        'id'       : ele['type'],
                                                        'title'    : ele['title'],
                                                        // tslint:disable-next-line:max-line-length
                                                        'translate': (ele['translations'] && ele['translations'][this.langIndex[this.currentLang]][this.currentLang]) || ele['title'],
                                                        'type'     :  (ele['subGroupCollapse'] ? 'collapse' : 'group' ),
                                                        'registerParams': ele['registerParams'],
                                                        'sort': ele['weight'],
                                                        // 'icon'     : ele['icon'].replace('icon', 'mdi'),
                                                        'mobile_icon'     : ele['mobile_icon'] || '',
                                                        'children' : []
                                                    };

                                                menuList.forEach(subGroupElement => {

                                                    let params = '';
                                                    // tslint:disable-next-line:max-line-length
                                                    if (subGroupElement && (subGroupElement['state'] === 'app.customregistries.registriesList' || subGroupElement['state'] === 'app.stepperList' || subGroupElement['state'] === 'app.processInstances.dashboards')) {
                                                        const temp = [];
                                                        // tslint:disable-next-line:max-line-length
                                                        if (subGroupElement['fuse2Url'] && (subGroupElement['state'] === 'app.customregistries.registriesList' || subGroupElement['state'] === 'app.stepperList')) {
                                                            const viewType = (subGroupElement['registerViewType']) ? subGroupElement['registerViewType'] : 'card';
                                                            params =  '/' + viewType;
                                                            // const expanded = (ele['expanded']) ? ele['expanded'] : 'expanded';
                                                            // params = '/' + expanded;
                                                        }
                                                        subGroupElement['registerParams'] = subGroupElement['registerParams'] || [];
                                                        subGroupElement['processParams'] =  subGroupElement['processParams'] || [];

                                                        // tslint:disable-next-line:max-line-length
                                                        if (subGroupElement['state'] === 'app.customregistries.registriesList' || subGroupElement['state'] === 'app.stepperList') {
                                                            subGroupElement['registerParams'].map(register => {
                                                                if (register.selected) {
                                                                    temp.push(register.key);
                                                                }
                                                            });
                                                        }

                                                        if (subGroupElement['state'] === 'app.processInstances.dashboards') {
                                                            subGroupElement['processParams'].map(process => {
                                                                if (process.selected) {
                                                                    temp.push(process.key);
                                                                }
                                                            });
                                                        }

                                                        if (subGroupElement['state'] === 'app.stepperList') {
                                                            params = '';
                                                        }


                                                        params  +=  (temp && temp.length ? '/' + temp.join(',') : '/all');
                                                    }

                                                    if (subGroupElement && (subGroupElement['state'] === 'app.gdprResources')) {
                                                        const  regGroups = [];
                                                        subGroupElement['registerGroups'].map(register => {
                                                            if (register.selected) {
                                                                regGroups.push(register.key);
                                                            }
                                                        });

                                                        params = `/gdpr-resources/${regGroups.join(',')}`;
                                                    }




                                                    // tslint:disable-next-line:max-line-length
                                                    if (subGroupElement && (subGroupElement['state'] ===  'app.pinboxOfficeContacts2.contacts') && this.selectedOfficeObject && this.selectedOfficeObject['apps'] &&  this.selectedOfficeObject['apps']['office_addressbook'] &&  this.selectedOfficeObject['apps']['office_addressbook']['id']) {
                                                        params = `/${this.selectedOfficeObject['apps']['office_addressbook']['id']}`;
                                                    }

                                                    // tslint:disable-next-line:max-line-length
                                                    if (subGroupElement && (subGroupElement['state'] === 'app.globalCalendar') && this.selectedOfficeObject && this.selectedOfficeObject['apps'] &&  this.selectedOfficeObject['apps']['Agenda'] &&  this.selectedOfficeObject['apps']['Agenda']['id']) {
                                                        params = `/${this.selectedOfficeObject['apps']['Agenda']['id']}`;
                                                    }

                                                    // tslint:disable-next-line:max-line-length
                                                    if (ele && ele['type'] && subGroupElement['mobile'] && subGroupElement && !subGroupElement['subGroup'] && !subGroupElement['hidden'] && subGroupElement['navigation'] && subGroupElement['navigation'].split('.').length >= 3 && subGroupElement['navigation'].split('.').includes(ele['type'])) {
                                                        // console.log(ele);
                                                        // console.log(element);
                                                        // console.log("***********************************")
                                                        
                                                        SubGroup['children'].push(
                                                            {
                                                                'id'       : subGroupElement['type'],
                                                                'title'    : subGroupElement['title'],
                                                                // tslint:disable-next-line:max-line-length
                                                                'translate': (subGroupElement['translations'] && subGroupElement['translations'][this.langIndex[this.currentLang]][this.currentLang]) || subGroupElement['title'],
                                                                'type'     : 'item',
                                                                'registerParams': subGroupElement['registerParams'],
                                                                'processId': subGroupElement['processId'],
                                                                'heading'   : subGroupElement['heading'],
                                                                'headingDesc'   : subGroupElement['headingDesc'],
                                                                'sort': subGroupElement['weight'],
                                                                // 'icon'     : ele['icon'].replace('icon', 'mdi'),
                                                                'mobile_icon'     : subGroupElement['mobile_icon'] || '',
                                                                // tslint:disable-next-line:max-line-length
                                                                'url'      : this.makeURL(ele['state'])
                                                            }
                                                        );
                                                    }


                                                });

                                                // tslint:disable-next-line:max-line-length
                                                if (ele && !ele['hidden'] && ele['navigation'] && ele['navigation'].split('.').length > 1 && ele['navigation'].split('.').includes(element['navigation'])) {

                                                    tempObj['children'].push(SubGroup);
                                                }
                                            }

                                        } else {
                                            let params = '';
                                            // tslint:disable-next-line:max-line-length
                                            if (ele && (ele['state'] === 'app.customregistries.registriesList' || ele['state'] === 'app.stepperList' || ele['state'] === 'app.processInstances.dashboards')) {
                                                const temp = [];
                                                if (ele['fuse2Url'] && (ele['state'] === 'app.customregistries.registriesList' || ele['state'] === 'app.stepperList')) {
                                                    const viewType = (ele['registerViewType']) ? ele['registerViewType'] : 'card';
                                                    params = '/' + viewType;
                                                    // const expanded = (ele['expanded']) ? ele['expanded'] : 'expanded';
                                                    // params = '/' + expanded;

                                                }
                                                ele['registerParams'] = ele['registerParams'] || [];
                                                ele['processParams'] =  ele['processParams'] || [];

                                                if (ele['state'] === 'app.customregistries.registriesList' || ele['state'] === 'app.stepperList') {
                                                    ele['registerParams'].map(register => {
                                                        if (register.selected) {
                                                            temp.push(register.key);
                                                        }
                                                    });
                                                }

                                                if (ele['state'] === 'app.processInstances.dashboards') {
                                                    ele['processParams'].map(process => {
                                                        if (process.selected) {
                                                            temp.push(process.key);
                                                        }
                                                    });
                                                }
                                                if (ele['state'] === 'app.stepperList') {                                            
                                                    params = '';
                                                }
                                                params +=  (temp && temp.length ? '/' + temp.join(',') : '/all');
                                            }

                                            if (ele && (ele['state'] === 'app.gdprResources')) {
                                                const  regGroups = [];
                                                ele['registerGroups'].map(register => {
                                                    if (register.selected) {
                                                        regGroups.push(register.key);
                                                    }
                                                });

                                                params = `/gdpr-resources/${regGroups.join(',')}`;
                                            }
                                            // tslint:disable-next-line:max-line-length
                                            if (ele && (ele['state'] ===  'app.pinboxOfficeContacts2.contacts') && this.selectedOfficeObject && this.selectedOfficeObject['apps'] &&  this.selectedOfficeObject['apps']['office_addressbook'] &&  this.selectedOfficeObject['apps']['office_addressbook']['id']) {
                                                params = `/${this.selectedOfficeObject['apps']['office_addressbook']['id']}`;
                                            }

                                            // tslint:disable-next-line:max-line-length
                                            if (ele && (ele['state'] === 'app.globalCalendar') && this.selectedOfficeObject && this.selectedOfficeObject['apps'] &&  this.selectedOfficeObject['apps']['Agenda'] &&  this.selectedOfficeObject['apps']['Agenda']['id']) {
                                                params = `/${this.selectedOfficeObject['apps']['Agenda']['id']}`;
                                            }

                                            // tslint:disable-next-line:max-line-length
                                            if (ele && !ele['hidden'] && ele['mobile'] && ele['navigation'] && ele['navigation'].split('.').length > 1 && ele['navigation'].split('.')[0].includes(element['navigation'])) {
                                                // console.log(ele);
                                                // console.log(element);
                                                // console.log("***********************************")
                                                tempObj['children'].push(
                                                    {
                                                        'id'       : ele['type'],
                                                        'title'    : ele['title'],
                                                        // tslint:disable-next-line:max-line-length
                                                        'translate': (ele['translations'] && ele['translations'][this.langIndex[this.currentLang]][this.currentLang]) || ele['title'],
                                                        'type'     : 'item',
                                                        'processId': ele['processId'],
                                                        'registerParams': ele['registerParams'],
                                                        'heading'   : ele['heading'],
                                                        'headingDesc'   : ele['headingDesc'],
                                                        'sort': ele['weight'],
                                                        // 'icon'     : ele['icon'].replace('icon', 'mdi'),
                                                        'mobile_icon'     : ele['mobile_icon'] || '',
                                                        'url'      : this.makeURL(ele['state'])
                                                    }
                                                );
                                            }
                                        }
                                        });
                                    this.model.push(tempObj);
                                // this.themeOptions.getCustomMenu();
                                }
                            });
                            resolve(this.model);
                        } else {
                            reject([]);
                        }
                    } else {
                        reject([]);
                    }
        });
    }

    makeURL(state: string): string {
        // return url.substr(url.lastIndexOf('/'), url.length);
        return this.urlMapping[state] || '/device-list';
    }
}
